html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

/* GALLERY STYLE */
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
}

.image-gallery-icon {
  filter: none !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  display: none !important;
}

.stf__wrapper {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}
.stf__parent {
  margin: auto;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.4rem;
}

.modal-body {
  padding: 1px !important;
}
